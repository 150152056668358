<template>
  <Layout navbarAbsolute navbarTransparent>
    <div class="ql-editor pb-0 px-0" >
      <img :src="bannerImage"
        style="height:500px;width:100%;object-fit:cover"
       /> 
       
      <div class="p-3" style="background:#0b5087">
        <div class="container">
          <div id="TEAM-1" style="padding:0 15%">
            <h1 class="text-white">
              OUR TEAM
            </h1>
            <p class="text-white">
              Our consulting team consists of local talents with global and intercultural experience, blending a multitude of professional and personal backgrounds in every work we do.
            </p>
          </div>
        </div>
      </div>

      <div style="background:#08365A">
        <div class=""> 
          <div class="d-flex pt-1 px-2 nav-team-container" style="overflow-x:scroll;gap:10px">
            <template v-for="(data, idx) in cats">
              <div :key="'NAV-item'+idx" @click="active_cats = idx" :class="`flex-fill ${idx == active_cats ? 'menu-active' : ''} d-flex`" style="cursor:pointer;height:50px;min-width:300px;border-top-left-radius:10px;border-top-right-radius:10px">
                <strong class="m-auto text-center text-wrap h6 text-white" style="font-weight:900;font-family:roboto">{{data.tc_name}}</strong>
              </div>
            </template> 
          </div>
        </div>
      </div>

      <div class="container mt-5" v-if="cats[active_cats]">
        <h2 class="text-center font-weight-bolder h1" style="color:#0B5087;font-family:roboto">{{cats[active_cats].tc_name}}</h2>
        <div v-html="cats[active_cats].tc_desc" class="ql-editor p-0 mt-1 mb-0">
        </div>
        <div class="row mt-4 justify-content-center">
              <div class="col-lg-3" v-for="item in teams" :key="item.team_id">
                <!-- <router-link :to="`/team/${item.team_id}`"> -->
                  <div class="card card-team shadow-lg">
                    <img :src="item.team_profile_pict" class="w-100" style="height:400px;object-fit:cover;object-position:center" />
                    <div class="px-2 py-1 detail">
                      <div class="description mb-1">
                        <p v-html="item.team_desc" class="mt-2 text-justify">
                        </p> 
                      </div>
                      <div style="color:#0B5087" class="h5 font-weight-bolder text-center">
                        <fitty :options="{ minSize: 7, maxSize: 16, multiLine: false }">
                            <template v-slot:content>
                              <span class="font-weight-bolder">{{item.team_name}}</span>
                            </template>
                        </fitty>
                      </div>
                      <div style="margin-top:.5em;" class="h5 text-secondary font-weight-bolder text-center">
                        <fitty :options="{ minSize: 7, maxSize: 16, multiLine: false }">
                            <template v-slot:content>
                                <span class="font-weight-bolder">{{item.team_role}}</span>
                            </template>
                        </fitty>
                      </div>
                      <h2 class="h6 text-secondary font-weight-bolder text-center" style="font-size:12px">{{item.tc_name}}</h2>
                      <div class="d-flex align-items-center justify-content-center mt-1" style="gap:8px">
                        <a :href="`mailto: ${item.team_email}`" target="_blank" class="btn btn-icon rounded-circle" style="background:#0B5087;color:white !important">
                          <i class="bx bx-envelope"></i>
                        </a> 
                        <a :href="item.team_redirect_mvp" target="_blank" class="btn btn-icon rounded-circle" style="background:#0B5087;color:white !important">
                          <i class="bx bxl-linkedin"></i>
                        </a> 
                      </div>
                      <!-- <div class="description">
                        <p v-html="item.team_desc" class="mt-2 text-justify">
                        </p>
                        <div style="background:#0B5087;color:white !important" class="btn float-right mt-1">
                          Detail
                        </div>
                      </div> -->
                    </div>
                  </div>
                <!-- </router-link> -->
              </div>
            </div>
      </div>

      <div style="background:#EDEDED" class="p-4 " id="TEAM-2">
        <h1><strong style="color:#0B5087;font-weight:900;font-family:roboto">LET'S WORK TOGETHER</strong></h1>
        Tell us about your project brief or just contact us
      </div>

    </div>
  </Layout>
</template>

<script>
import Layout from "@/layouts/main.vue";
import store from "@/store";
import moment from "moment";

export default {
  components: {
    Layout,
  },
  methods: { 
    async getCategories() {
      let temp = await store.dispatch(`teams/getCategories`);
      this.cats = temp
      this.$forceUpdate();
    },
    async getTeams() {
      let temp = await store.dispatch(`teams/getDataList`, {
        team: this.cats[this.active_cats]?.tc_id
      });
      this.teams = temp.filter(item => item.team_id != this.$route.params.id);
      this.$forceUpdate();
    },
    stripHtml(html)
    {
      let tmp = document.createElement("DIV");
      tmp.innerHTML = html;
      return tmp.textContent || tmp.innerText || "";
    },
    async getNextDatas(){
      let temp = await store.dispatch(`page_content/getDataList`, {
          lang_code: this.currentLocale,
          code: 'TEAM',  
      }) 
      temp?.map((item, key) => {
        if(item.code == 'TEAM-BANNER'){
          this.bannerImage = this.stripHtml(item.content)
        } else
        document.getElementById(item.code).innerHTML = item.content
      })
      this.$forceUpdate()
    },
  },
  computed: { 
    currentLocale() {
      return this.$store.state.locales.CURRENT
    },
  },
  mounted() { 
    this.getNextDatas()
    this.getTeams();
    this.getCategories();
  },
  watch: { 
    currentLocale(val) {
      this.getNextDatas();
    },
    active_cats(val) {
      this.getTeams();
    },
    cats(){
      this.getTeams();
    }
  },
  data() {
    return {
      moment,
      teams: '',
      cats: [],
      active_cats : 0,
      current: '',
      bannerImage: ''
    };
  },
};
</script>

<style scoped>
.menu-active {
  background: #F8F8F8;
}

.menu-active strong {
  color: #0B5087 !important;
}

.mask1 {
  -webkit-mask-image: linear-gradient(to bottom, transparent 0%, black 40%);
  mask-image: linear-gradient(to bottom, transparent 0%, black 90%);
}
.card-event {
  background-color: white;
  cursor: pointer;
}
.card-event:hover {
  background-color: rgb(228, 234, 255);
} 

::-webkit-scrollbar {
  height: 4px;             /* width of vertical scrollbar */
  border: 1px solid #d5d5d5;
}

@keyframes card-in-anim {
  from {
    display: none;
    opacity: 0;
    bottom: -30px;
  }
  to {
    display: block;
    opacity: 1;
    bottom: 0px;
  }
}
@keyframes card-in-anim-scale {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.05);
  }
}
@keyframes card-out-anim {
  from {
    border: 4px solid black;
    background: #f8f8f8;
    color: black;
  }
  to {
    border: 4px solid #575759;
    background: #121212;
    color: white;
  }
}

.card-team:hover .detail .description {
  animation-name: card-in-anim;
  animation-duration: 0.5s;
  display: block;
  opacity: 1;
  bottom: 0px;
}

.card-team:hover {
  animation-name: card-in-anim-scale;
  animation-duration: 0.5s;
  transform: scale(1.05);
  cursor: pointer;
}

.card-team {
  border-radius: 10px;
}
.card-team img {
  border-radius: 10px;
}

.card-team .detail .description {
  display: none;
  opacity: 0;
}
.card-team .detail {
  border-radius: 10px;
  /* display: none; */
  width: 100%;
  position: absolute;
  background: white;
  bottom: 0;
}
</style>
